<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Report/Site') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButtonGroup class="mr-2">
              <CButton v-for="Type in ViewTypeOptions" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'danger' : 'light')"  @click="ViewType = Type.value">{{ Type.label }}</CButton>
            </CButtonGroup>
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Report.Action') }}
              </template>
              <CDropdownItem @click="Print()">
                {{ $t('Report.PrintCSV') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto">
        <CRow class="text-nowrap" style="min-width: 900px;">
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">商店數</small><br>
              <strong class="h4">{{ Reporting.length || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">已付款筆數</small><br>
              <strong class="h4">{{ TotalStatistics.PaymentItems || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">已付款金額</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.PaymentAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="danger" class="my-1">
              <small class="text-muted">平均客單</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(AverageTotalAmount || 0) }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </CCardHeader>
      <template v-if="ViewType === 'Chart'">
        <CCardHeader class="w-100 overflow-auto">
          <CRow>
            <CCol col="12" class="mb-5">
              <CChartHorizontalBar :datasets="ChartDataSet" :labels="ChartLabels" />
            </CCol>
            <CCol col="12">
              <CChartDoughnut
                :datasets="ChartDoughnutDataSet"
                :options="ChartOptions"
                :labels="ChartPageLabels"
              />
            </CCol>
          </CRow>
        </CCardHeader>
      </template>
      <CCardBody v-if="ViewType === 'Table'" class="p-0">
        <CDataTable
          id="OrderSaleReport"
          :fields="Field"
          :items="Reporting"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #SiteName="{item}">
            <td class="text-info">
              {{ item.SiteName }}
            </td>
          </template>
          <template #TotalAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.TotalAmount) }}
            </td>
          </template>
          <template #PaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.PaymentAmount) }}
            </td>
          </template>
          <template #PaymentRealAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.PaymentRealAmount) }}
            </td>
          </template>
          <template #AverageTotalAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.AverageTotalAmount) }}
            </td>
          </template>
          <template #UnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.UnPaymentAmount) }}
            </td>
          </template>
          <template #ReturnPaymentAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.ReturnPaymentAmount) }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Init()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "門市銷售總表"
}
}
</route>

<script>
import { CChartHorizontalBar, CChartDoughnut } from '@coreui/vue-chartjs'
export default {
  name: 'OrderSaleReport',
  layout: 'manage',
  components: {
    CChartHorizontalBar,
    CChartDoughnut
  },
  data() {
    return {
      Loading: false,
      FilterModel: false,
      Submit: false,
      ShowFilter: 'All',
      TotalStatistics: {},
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01 00:00:00')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD 23:59:59')).unix() * 1000
      },
      ViewType: 'Table',
      ViewTypeOptions: [
        {
          value: 'Table',
          label: '表格'
        },
        {
          value: 'Chart',
          label: '圖表'
        }
      ],
      SearchFilterOriginMode: 'IN',
      Color: [
        '#4184f3', '#7cb442', '#ff5722', '#ff8f00', '#aa46bb',
        '#25abc0', '#9d9c23', '#5b6abf', '#ef6191', '#10786a',
        '#c11c5a', '#fa937f', '#34c0ff'
      ],
      TempReporting: [],
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      ChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      },
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      }
    }
  },
  computed: {
    StoreList() {
      return this.$store.state.storeList.filter(item => item.value !== 'Default') || []
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    },
    ChartLabels() {
      return ['已付款訂單金額']
    },
    ChartDataSet() {
      return this.Reporting.map((item, index) => {
        return {
          label: item.SiteName,
          borderColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          backgroundColor: index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index],
          fill: false,
          data: [item.PaymentAmount],
        }
      })
    },
    ChartPageLabels() {
      return this.Reporting.map((item) => item.SiteName)
    },
    ChartDoughnutDataSet () {
      return [
        {
          backgroundColor: this.Reporting.map((item, index) => index > 12 ? this.Color[Math.floor(Math.random() * 11)] : this.Color[index]),
          data: this.Reporting.map((item) => item.TotalItems),
        }
      ]
    },
    Field() {
      return [
        {key: 'SiteName', label: '商店名稱'},
        {key: 'TotalItems', label: '訂單筆數'},
        {key: 'TotalAmount', label: '訂單金額'},
        {key: 'PaymentItems', label: '已付款筆數'},
        {key: 'PaymentAmount', label: '已付款訂單金額'},
        {key: 'AverageTotalAmount', label: '平均客單'},
        {key: 'UnPaymentItems', label: '未付款筆數'},
        {key: 'UnPaymentAmount', label: '未付款訂單金額'},
        {key: 'ReturnPaymentItems', label: '已退款筆數'},
        {key: 'ReturnPaymentAmount', label: '已退款訂單金額'}
      ]
    },
    AverageTotalAmount() {
      const AverageTotal = this.TempReporting.reduce((acc, item) => {
        return acc + (item.PaymentAmount / item.PaymentItems)
      }, 0)

      return Math.round(AverageTotal / this.TempReporting.length)
    },
    Reporting() {
      if (this.StoreList.length === 0) {
        return []
      }
      const project = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
      return this.TempReporting.filter(item => item._id && item._id !== 'Default').map((item) => {
        // 商店資料
        item.StoreData = this.StoreList.find(page => page.value === item._id) || {
          label: item._id === project ? '網路商店' : item._id.replace('POS-', '').replace('MENU-', ''),
          value: item._id.replace('POS-', '').replace('MENU-', ''),
          Variable: {}
        }
        item.SiteName = item.StoreData.label
        item.AverageTotalAmount = Math.round(item.PaymentAmount / item.PaymentItems)

        return item
      }).sort((a, b) => b.TotalAmount - a.TotalAmount)
    }
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.FilterModel = false
      return Promise.all([
        this.GetReporting()
      ]).then(() => {
        return true
      }).catch((err) => {
        console.log(err)
        throw err
      })
    },
    GetReporting() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/reporting/site',
          Data
        }
      }).then(({data}) => {
        this.Loading = false
        this.TotalStatistics = data.TotalStatistics
        this.TempReporting = data.Data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01')).unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
      }
      this.Init()
    },
    Print() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/order/reporting/site',
          Data,
          type: 'site'
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `門市銷售總表-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    FormatValue (item) {
      switch (item.Type) {
        case 'Price':
          return '$' + item.Value
        case 'Percent':
          return item.Value + '%'
        default:
          return item.Value
      }
    }
  }
}
</script>

<style lang="scss">
#OrderSaleReport tbody td:first-child {
  vertical-align: middle;
}

.WidgetList {
  .card-body {
    small {
      height: 36px;
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
